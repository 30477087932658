// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.process_processes-table__diqr4 {
  margin: 0 var(--space-base);
}
.process_processes-table-actions__npTrW {
  padding-bottom: var(--space-base);
}
.process_processes-table-actions-right__o002F {
  float: right;
  margin-right: var(--space-large);
}`, "",{"version":3,"sources":["webpack://./src/apps/processes/process.module.scss"],"names":[],"mappings":"AACE;EACE,2BAAA;AAAJ;AACI;EACE,iCAAA;AACN;AAAM;EACE,YAAA;EACA,gCAAA;AAER","sourcesContent":[".processes {\n  &-table {\n    margin: 0 var(--space-base);\n    &-actions {\n      padding-bottom: var(--space-base);\n      &-right {\n        float: right;\n        margin-right: var(--space-large);\n      }\n    }\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"processes-table": `process_processes-table__diqr4`,
	"processes-table-actions": `process_processes-table-actions__npTrW`,
	"processes-table-actions-right": `process_processes-table-actions-right__o002F`
};
export default ___CSS_LOADER_EXPORT___;
