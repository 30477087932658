import React from 'react';
import { Calendar } from 'primereact/calendar';
import { MultiSelect } from 'primereact/multiselect';
import { TriStateCheckbox } from 'primereact/tristatecheckbox';

const dateFilterTemplate = (options) => {
  return <Calendar value={options.value} onChange={(e) => {return options.filterCallback(e.value, options.index);}}
    dateFormat="dd/mm/yy" placeholder="dd/mm/yyyy" mask="99/99/9999" />;
};

const dropdownFilterTemplate = (options) => {
  return <MultiSelect value={options.value} options={options.filterModel.options} onChange={(e) => options.filterCallback(e.value)}
    placeholder="Any" className="p-column-filter" />;
};

const booleanFilter = (options) => {
  return <div className="flex align-items-center gap-2">
    <label htmlFor="verified-filter" className="font-bold">
      {options.filterModel.title}
    </label>
    <TriStateCheckbox inputId="verified-filter" value={options.value} onChange={(e) => options.filterCallback(e.value)} />
  </div>;
};

const Filters = {
  dateFilterTemplate,
  dropdownFilterTemplate,
  booleanFilter,
};

export default Filters;
