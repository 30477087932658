import { api } from '../utils';
let userChecked = false;

async function login(username, password, totpCode, newPassword) {
  const { user } = await api.send({
    endpoint: '/identity/login',
    method: 'POST',
    json: { username, password, totpCode, newPassword },
  });
  localStorage.setItem('user', JSON.stringify(user));
  return { user };
}

function verifyUser(username, totpCode) {
  return api.send({
    endpoint: '/identity/login/verifyMFA',
    method: 'POST',
    json: { username, totpCode, deviceName: 'User device' },
  });
}

function getLoggedInUser() {
  return JSON.parse(localStorage.getItem('user'));
}

function logout() {
  localStorage.removeItem('user');
  // doesn't use router as this can only navigate within /apps
  return api.send({ endpoint: '/identity/logout' })
    .finally(() => {
      if (window.location.pathname !== '/' && !window.location.pathname.includes('password')) {
        window.location.href = '/';
      }
    });
}

function createResetToken(username) {
  return api.send({
    endpoint: '/identity/forgottenPassword',
    method: 'POST',
    json: { username },
  });
}

function resetPassword(json) {
  return api.send({
    endpoint: '/identity/resetPassword',
    method: 'POST',
    json,
  });
}

// checks whether user is logged in
// if they are, sets state management object
// accepts cookie policy
// and redirects to the dashboard
function checkLoginStatus() {
  if (userChecked) return;
  return api.send({ endpoint: '/identity' })
    .then(({ user }) => {
      if (user) {
        localStorage.setItem('user', JSON.stringify(user));
        userChecked = true;
        if (window.location.pathname === '/') {
          window.location.href = '/dashboard';
        }
      }
      return user;
    });
}

function isPermitted(role) {
  const user = getLoggedInUser();
  let permission = false;
  if (user && (user.roles.includes('OVERALL_ADMIN') || user.roles.includes(role))) {
    permission = true;
  }
  return permission;
}

function setLogoutTime(time) {
  const user = getLoggedInUser();
  if (user) user.expires = time;
  localStorage.setItem('user', JSON.stringify(user));
}

export const userService = {
  checkLoginStatus,
  createResetToken,
  login,
  verifyUser,
  logout,
  getLoggedInUser,
  isPermitted,
  setLogoutTime,
  resetPassword,
};
