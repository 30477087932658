import React from 'react';
import PropTypes from 'prop-types';
import styles from './orderFilter.module.scss';
import { utils } from '../utils';

const OrderFilterCard = ({ props }) =>
  <div data-cy={`${props.name}-summary-card`} className={`${styles['order-card']}
    layer2 col-${props.colSize} grid-border-layer-2
    ${props.isActive ? `order-card-active ${styles['order-card-active']}` : 'hover-enabled'}`}
  onClick={(e) => props.onClick(e)}>
    <div className={styles['order-card-container']}>
      {props.isActive
        ? <i className={`${styles['active-close-icon']} pi pi-times`} onClick={(e) => props.onActiveClick(e, 'Total')}></i>
        : null}
      <div className={`${styles['order-card-value']} ${styles.large}`}>
        <span data-cy={`${props.name}-summary-card-value`} className={styles['order-card-value-number']}>
          {Math.round((props.value) * 10) / 10}
        </span>
      </div>
      <div className={`${styles['order-card-name']} hidden md:block lg:hidden xl:block`}>{utils.capitalize(props.name)}</div>
      <div className={`${styles['order-card-name']} block md:hidden lg:block xl:hidden`}>{utils.capitalize(props.shortName)}</div>
    </div>
  </div>;

OrderFilterCard.propTypes = {
  props: PropTypes.object,
  name: PropTypes.string,
  shortName: PropTypes.string,
  value: PropTypes.number,
  colSize: PropTypes.number,
  isActive: PropTypes.bool,
  onClick: PropTypes.func,
  onActiveClick: PropTypes.func,
};

export default OrderFilterCard;
