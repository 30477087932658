import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { userService } from '../../services';
import styles from './forgotPassword.module.scss';
import logo from '../../assets/logo.png';

export default function ForgottenPassword() {
  const [emailId, setEmailId] = useState('');
  const [status, setStatus] = useState('ready');
  const navigate = useNavigate();

  function handleKeyPress(key) {
    if (key === 'Enter') resetPassword();
  }

  async function resetPassword() {
    try {
      setStatus('Loading');
      await userService.createResetToken(emailId);
      setStatus('EmailSent');
    }
    catch (e) {
      setStatus('EmailSent');
    }
  }

  const PasswordEmailSentMessage = () => (
    <div className='message' data-cy='forgotten-password-email-message'>
      <p>If <strong>{emailId}</strong> is associated with a valid account you will receive an email containing
        a reset token, proceed <a href='/set-password' className={styles.link} onClick={() => navigate('/set-password')}>
        here to reset your password.</a></p>
      <p>Didn&apos;t receive the email?</p>
      <p>Please check your spam or bulk folders for a message from no-reply@verificationemail.com.
        If no email is found, check the email address associated with this account and try again.</p>
    </div>
  );

  return (
    <div className='heading'>
      <div className='logoContainer'>
        <img src={logo} alt='midlands steel logo' />
      </div>
      <div className='w-25rem unauthed-section'>
        {status === 'EmailSent'
          ? <PasswordEmailSentMessage />
          : <div>
            <div className='mt-8 p-1'>
              <InputText className={`w-full bg-none color ${status}`} type='text' placeholder='Email' value={emailId}
                onChange={(e) => setEmailId(e.target.value)} onKeyDown={(e) => handleKeyPress(e.key)} />
            </div>
            <div className='p-1 mt-3'>
              <Button className='w-full bg-none color' disabled={!(emailId) || status === 'Loading'} label='Next' onClick={resetPassword} />
            </div>
            <div className={styles['link-div']}>
                Already have an account? <a href='/' className={styles.link} onClick={() => navigate('/')}>Return to login</a>
            </div>
          </div>}
      </div>
    </div>
  );
}
