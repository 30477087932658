import React from 'react';
import { useNavigate } from 'react-router-dom';
import style from './header.module.scss';
import logo from '../assets/logo.png';

function Header() {
  const navigate = useNavigate();
  return (
    <div className={style.header}>
      <a href='/dashboard' onClick={() => navigate('/dashboard')}>
        <img className={style['header-logo']} src={logo} alt='Midlands steel home logo' onClick={() => navigate('/dashboard')}/>
      </a>
    </div>
  );
}

export default Header;
