// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.configurations_config-table-actions__QNlgK {
  left: calc(100% - 188px);
  position: relative;
  padding-bottom: var(--space-base);
  width: 180px;
}
.configurations_config-table-actions-button__OBzhJ {
  background: #FEF4F3 !important;
}
.configurations_config-table-container__atpEj {
  margin: 0 var(--space-base);
}`, "",{"version":3,"sources":["webpack://./src/apps/configurations/configurations.module.scss"],"names":[],"mappings":"AAEI;EACE,wBAAA;EACA,kBAAA;EACA,iCAAA;EACA,YAAA;AADN;AAEM;EACE,8BAAA;AAAR;AAGI;EACE,2BAAA;AADN","sourcesContent":[".config {\n  &-table {\n    &-actions {\n      left: calc(100% - 188px);\n      position: relative;\n      padding-bottom: var(--space-base);\n      width: 180px;\n      &-button {\n        background: #FEF4F3 !important;\n      }\n    }\n    &-container {\n      margin: 0 var(--space-base);\n    }\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"config-table-actions": `configurations_config-table-actions__QNlgK`,
	"config-table-actions-button": `configurations_config-table-actions-button__OBzhJ`,
	"config-table-container": `configurations_config-table-container__atpEj`
};
export default ___CSS_LOADER_EXPORT___;
