// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.configurationTable_configurations-table__LU0CB {
  height: inherit;
}`, "",{"version":3,"sources":["webpack://./src/components/configurationTable.module.scss"],"names":[],"mappings":"AAAA;EACE,eAAA;AACF","sourcesContent":[".configurations-table {\n  height: inherit;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"configurations-table": `configurationTable_configurations-table__LU0CB`
};
export default ___CSS_LOADER_EXPORT___;
