import { api } from '../utils';

function getProcesses() {
  return api.send({
    endpoint: '/processes',
  });
}

function sortByType(processes) {
  return (processes || []).reduce((items, process) => {
    items[process.type].push(process);
    return items;
  }, {
    Success: [],
    Failed: [],
    Pending: [],
    Unprocessed: [],
  });
}

export const processService = {
  getProcesses,
  sortByType,
};
