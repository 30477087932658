// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:root {
  --header1: 18px;
  --header2: 16px;
  --date-size: 12px;
  --text-size: 14px;
  --space-base: 8px;
  --space-small: 4px;
  --space-large: 16px;
  --space-xl: 24px;
  --space-xxl: 32px;
  --space-xxxl: 48px;
  --disabled-opacity: 70%;
  --font-bold: bold;
  --font-normal: normal;
  --header: #F2F2f2;
  --menu: #EA5C4D;
  --red: #EA0A17;
  --gray: #9F9594;
  --blue: #6FB2CE;
  --purple: #C083C5;
  --login-background: #F3D7D4;
  --login-text: #606060;
  --menu-text: #f2f2f2;
  --menu-hover: #F3D7D4;
  --table-text: #242424;
}`, "",{"version":3,"sources":["webpack://./src/styles/variables.scss"],"names":[],"mappings":"AAAA;EACE,eAAA;EACA,eAAA;EACA,iBAAA;EACA,iBAAA;EACA,iBAAA;EACA,kBAAA;EACA,mBAAA;EACA,gBAAA;EACA,iBAAA;EACA,kBAAA;EACA,uBAAA;EACA,iBAAA;EACA,qBAAA;EACA,iBAAA;EACA,eAAA;EACA,cAAA;EACA,eAAA;EACA,eAAA;EACA,iBAAA;EACA,2BAAA;EACA,qBAAA;EACA,oBAAA;EACA,qBAAA;EACA,qBAAA;AACF","sourcesContent":[":root {\n  --header1: 18px;\n  --header2: 16px;\n  --date-size: 12px;\n  --text-size: 14px;\n  --space-base: 8px;\n  --space-small: 4px;\n  --space-large: 16px;\n  --space-xl: 24px;\n  --space-xxl: 32px;\n  --space-xxxl: 48px;\n  --disabled-opacity: 70%;\n  --font-bold: bold;\n  --font-normal: normal;\n  --header: #F2F2f2;\n  --menu: #EA5C4D;\n  --red: #EA0A17;\n  --gray: #9F9594;\n  --blue: #6FB2CE;\n  --purple: #C083C5;\n  --login-background: #F3D7D4;\n  --login-text: #606060;\n  --menu-text: #f2f2f2;\n  --menu-hover: #F3D7D4;\n  --table-text: #242424;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
