import dateformat from 'dateformat';
import Pluralize from 'pluralize';
const rUrl = require('url');

function generateGuid() {
  return '10000000-1000-4000-8000-100000000000'.replace(/[018]/g, c =>
    ((c ^ crypto.getRandomValues(new Uint8Array(1))[0]) & (15 >> c / 4)).toString(16),
  );
}

/**
 * Create a class="" string for any truthy keys in the map
 *
 * @example
 * html`<mycomponent class="${classes({
 *   enabled,
 *   even: i % 2 === 0,
 *   blue: 'color' === blue,
 *   [`row-${num}`]: num < 5,
 * })}">`
 */
function classes(classMap) {
  return (
    Object.entries(classMap)
      // Remove any non-truthy values
      .filter(entry => !!entry[1])
      // Pull out the keys
      .map(([key]) => key)
      .join(' ')
  );
}

function capitalize(str) {
  return str.substr(0, 1).toUpperCase() + str.substr(1);
}

function datetime({ date = new Date(), timezone, format }) {
  let ms = (date instanceof Date ? date : new Date(date)).getTime();
  if (timezone) {
    ms += 1000 * 60 * 60 * timezone.offset;
  }

  if (timezone && timezone.name && timezone.name.substr(0, 7) === 'America') {
    format = format.replace('dd mmm', 'mmm dd');
  }
  return dateformat(ms, format);
}

function offset(timezone) {
  return timezone
    ? `(UTC${timezone.offset < 0 ? timezone.offset : `+${timezone.offset}`})`
    : `GMT(UTC+0)`;
}

const whitelisted = `_.#&!+-`;
const regexps = {
  email: {
    pattern: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,// eslint-disable-line no-useless-escape,max-len
    message: 'is invalid',
  },
  specialChars: {
    pattern: /^(?!\s)[A-Za-zÀ-ȕ0-9\s-]*(?!\s)$/,
    message: 'must not contain special characters',
  },
  whitelisted: {
    pattern: `^[A-Za-zÀ-ȕ0-9\\s${whitelisted}]+$`,
    message: `must only include ${whitelisted}, letters and numbers`,
  },
  noSpace: {
    pattern: /^[^\s].*[^\s]$/,
    message: 'must not start or end with a space',
  },
  consecutiveSpecialChars: {
    pattern: `^((?!([\\s${whitelisted}][\\s${whitelisted}])).)*$`,
    message: `must not include multiple consecutive ${whitelisted} characters or spaces`,
  },
  percentage: {
    pattern: /^100$|^\d{0,2}(\.\d{1,5})? *%?$/,
    message: 'must be a valid percentage',
  },
  url: {
    pattern: /^https?:\/\/[a-z0-9.-/]*$/,
    message: 'must be a valid url',
  },
  Numbers: {
    pattern: /^[0-9]{1,}$/,
    message: 'must be a valid number',
  },
  domain: {
    pattern: /^[a-z0-9](?:[a-z0-9-.]{0,61}[a-z0-9])?(?:\\.[a-z0-9](?:[.-0-9a-z]{0,61}[0-9a-z])?)*$/,
    message: 'must be a valid domain',
  },
  phoneNumbers:{
    pattern: /^(?=.*[0-9])[- + 0-9]+$/,
    message: 'must be a valid number',
  },
};

const dateFormatting = {
  shortTime: (date, timezone) => datetime({ date, timezone, format: 'HH:MM' }),

  dateTime: (date, timezone) => datetime({ date, timezone, format: 'UTC:dd mmm yyyy HH:MM:ss' }),
  shortDateTime: (date, timezone) => datetime({ date, timezone, format: 'UTC:dd mmm HH:MM' }),

  date: (date, timezone) => datetime({ date, timezone, format: 'UTC:dd mmm yyyy' }),
  shortDate: (date, timezone) => datetime({ date, timezone, format: 'UTC:dd mmm' }),
  month: (date, timezone) => datetime({ date, timezone, format: 'UTC:mmm' }),
  longMonth: (date, timezone) => datetime({ date, timezone, format: 'UTC:mmmm' }),
  longMonthYear: (date, timezone) => datetime({ date, timezone, format: 'UTC:mmmm yyyy' }),
  offset: offset,
};

function pluralise(singular, count) {
  const isPlural = count !== 1;
  const isAre = isPlural ? 'are' : 'is';
  const pluralised = Pluralize(singular, count);
  const sentence = `${isAre} ${count} ${pluralised}`;
  return { isAre, pluralised, sentence };
}

function getFile(event, isCSV) {
  return new Promise(resolve => {
    // if from a drop, dataTransfer is set
    // if from file select, it's event.target
    const file = (event.dataTransfer || event.target).files.item(0);
    const reader = new FileReader();
    reader.onloadend = () => {
      const data = {
        name: file.name,
        type: file.name.split('.').pop().toLowerCase(),
        size: Math.ceil(file.size / 1024 / 1024),
        data: isCSV ? reader.result : reader.result.split(',')[1], // remove the beginning of the string that adds encoding information
      };
      resolve(data);
    };
    if (isCSV) {
      reader.readAsText(file);
    } else {
      reader.readAsDataURL(file);
    }
  });
}

function validateFile(file, validation = {}) {
  if (!file) {
    return validation.optional ? false : 'Please upload a file';
  } else if (validation.maxSize && file.size > validation.maxSize) {// maxSize of 0 allows unlimited size
    return `File size (${file.size}Mb) exceeds limit of ${validation.maxSize}Mb`;
  } else if (!file.size) {
    return 'File size must be larger than 0Kb';
  } else if (validation.blacklist && validation.blacklist.includes(file.type)) {
    return `Unsupported file type (.${file.type})`;
  } else if (validation.whitelist && !validation.whitelist.includes(file.type)) {
    const acceptedTypeString = arrayToSentence(validation.whitelist.map(type => `.${type}`));
    return `Unsupported file type (.${file.type}). Must be ${acceptedTypeString}`;
  } else {
    return false;
  }
}

function arrayToSentence(array) {
  return array.map((item, idx) => {
    const join = idx
      ? (idx === array.length - 1 ? ' or ' : ', ')
      : '';
    return `${join}${item}`;
  }).join('');
}

function isString(variable) {
  return (typeof variable === 'string' || variable instanceof String);
}
function getUrlParam(urlString, param) {
  return rUrl.parse(urlString,param).query[param];
}

function setSeconds(seconds) {
  const date = new Date(seconds * 1000);
  return date.toISOString().substr(11, 8);
}

function getUTCDate(month) {
  // get the start of the month in utc time not local browser time
  // it has to be utc as it gets passed back to the service which is utc only
  const utcYear = new Date().getUTCFullYear();
  return new Date(Date.UTC(utcYear, month));
}

function deCamel(camel) {
  return camel.replace(/([a-z])([A-Z])/g, '$1 $2').split(' ').join(' ');
}

function getDateOfDayOfWeek(dayNumber) {
  let d = new Date(),
    year = d.getYear(),
    week = 1,
    days = [];
  d.setDate(1);
  d.setMonth(0);

  // Get the first day of dayNumber eg monday in the year
  while (d.getDay() !== dayNumber) {
    d.setDate(d.getDate() + 1);
  }

  // Get all the other day of dayNumber eg monday in the year
  while (d.getYear() === year) {
    var pushDate = new Date(d.getTime());
    days.push({ text:`Week ${week} - ${pushDate.getDate()}/${(pushDate.getMonth() + 1)}/${pushDate.getFullYear()}`, date: pushDate });
    week++;
    d.setDate(d.getDate() + 7);
  }
  return days;
}

const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun',
  'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

export const utils = {
  generateGuid,
  classes,
  capitalize,
  datetime,
  regexps,
  dateFormatting,
  pluralise,
  getFile,
  validateFile,
  arrayToSentence,
  isString,
  getUrlParam,
  setSeconds,
  getUTCDate,
  deCamel,
  months,
  getDateOfDayOfWeek,
};
