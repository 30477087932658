// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.login_errorMessage__EXj\\+Q {
  padding: var(--space-base);
}

.login_qr-background__wuprY {
  background: white;
  margin: 0 calc((25rem - 128px) / 2);
}`, "",{"version":3,"sources":["webpack://./src/apps/loginPage/login.module.scss"],"names":[],"mappings":"AAAA;EACE,0BAAA;AACF;;AAEA;EACE,iBAAA;EACA,mCAAA;AACF","sourcesContent":[".errorMessage {\n  padding: var(--space-base);\n}\n\n.qr-background {\n  background: white;\n  margin: 0 calc((25rem - 128px) / 2);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"errorMessage": `login_errorMessage__EXj+Q`,
	"qr-background": `login_qr-background__wuprY`
};
export default ___CSS_LOADER_EXPORT___;
