// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.setPassword_passwords-rule__HgXTX {
  display: inline-block;
  margin-left: var(--space-base);
}
.setPassword_passwords-rule-icon__R5DAl {
  padding-right: var(--space-base);
}

.setPassword_password-message-button__Z6s3C {
  display: block;
  margin-top: var(--space-base);
}`, "",{"version":3,"sources":["webpack://./src/apps/setPassword/setPassword.module.scss"],"names":[],"mappings":"AAAA;EACI,qBAAA;EACA,8BAAA;AACJ;AAAI;EACI,gCAAA;AAER;;AAEA;EACI,cAAA;EACA,6BAAA;AACJ","sourcesContent":[".passwords-rule {\n    display: inline-block;\n    margin-left: var(--space-base);\n    &-icon {\n        padding-right: var(--space-base);\n    }\n}\n\n.password-message-button {\n    display: block;\n    margin-top: var(--space-base);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"passwords-rule": `setPassword_passwords-rule__HgXTX`,
	"passwords-rule-icon": `setPassword_passwords-rule-icon__R5DAl`,
	"password-message-button": `setPassword_password-message-button__Z6s3C`
};
export default ___CSS_LOADER_EXPORT___;
