import React, { useEffect, useCallback, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { FilterMatchMode } from 'primereact/api';
import { default as Filters } from './filters';
import { configService } from '../services';
import { utils } from '../utils';
import styles from './configurationTable.module.scss';


function Configurations({ smallTable }) {
  const navigate = useNavigate();
  const updateTemplate = (rowData) => <a href={`/configurations/${rowData.id}`}
    onClick={() => navigate(`/order/${rowData.analysisCode}`)}>Update</a>;
  const [configurations, setConfigurations] = useState([]);
  const dt = useRef(null);
  const columns = [
    { field: 'stripped_file_name', header: 'File name', sortable: true, filter: true,
      filterElement: Filters.dropdownFilterTemplate, showFilterMatchModes: false },
    { field: 'CriticallityOfFlow', header: 'Criticality', sortable: true, filter: true, filterElement: Filters.booleanFilter,
      hidden:smallTable },
    { field: 'Protocol', sortable: true, filter: true, filterElement: Filters.dropdownFilterTemplate, showFilterMatchModes: false,
      hidden:smallTable },
    { field: 'SenderClient', header: 'Sender', sortable: true,
      filter: true, filterElement: Filters.dropdownFilterTemplate, showFilterMatchModes: false },
    { field: 'DestinationClient', header: 'Destination', sortable: true, filter: true, filterElement: Filters.dropdownFilterTemplate,
      showFilterMatchModes: false },
    { field: 'ProcessingTimeRange1', header: 'Time range', sortable: true, hidden: smallTable },
    { field: 'DestinationCompany', sortable: true, filter: true, filterElement: Filters.dropdownFilterTemplate,
      showFilterMatchModes: false, hidden:smallTable },
    { field: 'DestinationIPOrURL', header: 'Destination address', sortable: true, filter: true, hidden:smallTable },
    { field: 'DestinationPath', sortable: true, filter: true, hidden:smallTable,
      filterElement: Filters.dropdownFilterTemplate, showFilterMatchModes: false },
    { field: 'IsPartOfMultiFile', header: 'Multi file', dataType: 'boolean', sortable: true,
      filter: true, filterElement: Filters.booleanFilter, hidden:smallTable },
    { field: 'PGPDecrypt', header: 'PGP files', dataType: 'boolean', sortable: true,
      filter: true, filterElement: Filters.booleanFilter, hidden:smallTable },
    { field: 'id', filter: false, header: 'Update', body: updateTemplate },
  ];
  const [filters, setFilters] = useState([]);
  const [loading, setLoading] = useState(true);

  const fetchData = useCallback(async () => {
    let config = [];
    try {
      config = (await configService.getConfigurations()).config;
    } catch (e) {
      config = [];
    }
    const filterOptions = {
      stripped_file_name: [],
      Protocol: [],
      SenderClient: [],
      DestinationCompany: [],
      DestinationPath: [],
      DestinationClient: [],
    };
    const dropdownKeys = Object.keys(filterOptions);
    (config || []).forEach( item => {
      dropdownKeys.forEach(key => filterOptions[key].push(item[key]));
    });
    // remove all the duplicate dropdown entries
    dropdownKeys.forEach(key => filterOptions[key] = [...new Set(filterOptions[key])]);
    setFilters({
      stripped_file_name: { matchMode: FilterMatchMode.IN, options: filterOptions.stripped_file_name },
      CriticallityOfFlow: { matchMode: FilterMatchMode.IN, options: filterOptions.CriticallityOfFlow },
      Protocol: { matchMode: FilterMatchMode.IN, options: filterOptions.Protocol },
      SenderClient: { matchMode: FilterMatchMode.IN, options: filterOptions.SenderClient },
      DestinationClient: { matchMode: FilterMatchMode.IN, options: filterOptions.DestinationClient },
      DestinationCompany: { matchMode: FilterMatchMode.IN, options: filterOptions.DestinationCompany },
      DestinationIPOrURL: { matchMode: FilterMatchMode.CONTAINS },
      DestinationPath: { matchMode: FilterMatchMode.IN, options: filterOptions.DestinationPath },
      IsPartOfMultiFile: { matchMode: FilterMatchMode.IN },
      PGPDecrypt: { matchMode: FilterMatchMode.IN },
    });
    setConfigurations(config);
    setLoading(false);
  }, []);

  useEffect(() => { fetchData(); }, [fetchData]);

  function generateColumn(column) {
    column.header = column.header || utils.capitalize(column.field);
    column.filterField = column.filterField || column.field;
    return <Column {...column} key={column.field}
    ></Column>;
  }

  return (
    <div className={styles['configurations-table']} data-cy={`configurations-table`}>
      <DataTable ref={dt} value={configurations} paginator rows={smallTable ? 5 : 15}
        rowsPerPageOptions={smallTable ? [5, 10, 15] : [15, 30, 60]}
        loading={loading} filters={filters}>
        {columns.map(column => generateColumn(column))}
      </DataTable>
    </div>);
}

Configurations.propTypes = {
  smallTable: PropTypes.bool,
};


export default Configurations;
