import React from 'react';
import styles from './serviceErrorMessage.module.scss';

const ServiceErrorMessage = () => {
  return (
    <div className={styles.messageContainer}>
      <i className='pi pi-exclamation-triangle'></i>
      <p>No service available</p>
    </div>
  );
};

export default ServiceErrorMessage;
