// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.forgotPassword_link-div__w\\+F5V {
  padding-top: var(--space-base);
  padding-left: var(--space-base);
  display: inline;
}

.forgotPassword_link__uLXpq {
  padding-left: var(--space-base);
  color: var(--login-text) !important;
  text-align: center;
  display: inline;
  -webkit-text-decoration: underline solid var(--login-text);
          text-decoration: underline solid var(--login-text);
}
.forgotPassword_link__uLXpq:hover {
  cursor: pointer;
}`, "",{"version":3,"sources":["webpack://./src/apps/forgotPassword/forgotPassword.module.scss"],"names":[],"mappings":"AAAA;EACI,8BAAA;EACA,+BAAA;EACA,eAAA;AACJ;;AACA;EACI,+BAAA;EACA,mCAAA;EACA,kBAAA;EACA,eAAA;EACA,0DAAA;UAAA,kDAAA;AAEJ;AADI;EACI,eAAA;AAGR","sourcesContent":[".link-div {\n    padding-top: var(--space-base);\n    padding-left: var(--space-base);\n    display: inline;\n}\n.link {\n    padding-left: var(--space-base);\n    color: var(--login-text) !important;\n    text-align: center;\n    display: inline;\n    text-decoration: underline solid var(--login-text);\n    &:hover {\n        cursor: pointer;\n    }\n}\n\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"link-div": `forgotPassword_link-div__w+F5V`,
	"link": `forgotPassword_link__uLXpq`
};
export default ___CSS_LOADER_EXPORT___;
