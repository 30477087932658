// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.orderFilter_order-card__o49\\+Y {
  display: inline-block;
  margin: 0 0 var(--space-base) 0;
  cursor: pointer;
  padding: 0 !important;
  position: relative;
}
.orderFilter_order-card-value__1vvAX {
  font-size: var(--space-xxl);
  text-align: center;
  font-weight: 600;
}
.orderFilter_order-card-value__1vvAX.orderFilter_large__-bYLr {
  font-size: var(--space-xxxl);
}
.orderFilter_order-card-name__ozGlO {
  font-size: var(--heading1);
  text-align: center;
  padding-bottom: var(--space-base);
}
.orderFilter_order-card-active__KTw\\+C {
  cursor: auto;
}
.orderFilter_order-card-active__KTw\\+C .orderFilter_order-card-container__puSvz {
  border: 1px solid;
}
.orderFilter_order-card-active__KTw\\+C .orderFilter_active-close-icon__fSgyB {
  cursor: pointer;
  position: absolute;
  right: var(--space-base);
  top: var(--space-base);
}`, "",{"version":3,"sources":["webpack://./src/components/orderFilter.module.scss"],"names":[],"mappings":"AACE;EACI,qBAAA;EACA,+BAAA;EACA,eAAA;EACA,qBAAA;EACA,kBAAA;AAAN;AAEM;EACI,2BAAA;EACA,kBAAA;EACA,gBAAA;AAAV;AACU;EACE,4BAAA;AACZ;AAGM;EACI,0BAAA;EACA,kBAAA;EACA,iCAAA;AADV;AAGM;EACM,YAAA;AADZ;AAGY;EACI,iBAAA;AADhB;AAIY;EACI,eAAA;EACA,kBAAA;EACA,wBAAA;EACA,sBAAA;AAFhB","sourcesContent":[".order {\n  &-card {\n      display: inline-block;\n      margin: 0 0 var(--space-base) 0;\n      cursor: pointer;\n      padding: 0 !important;\n      position: relative;\n\n      &-value {\n          font-size: var(--space-xxl);\n          text-align: center;\n          font-weight: 600;\n          &.large {\n            font-size: var(--space-xxxl);\n          }\n      }\n\n      &-name {\n          font-size: var(--heading1);\n          text-align: center;\n          padding-bottom: var(--space-base);\n      }\n      &-active {\n            cursor: auto;\n\n            .order-card-container {\n                border: 1px solid;\n            }\n\n            .active-close-icon {\n                cursor: pointer;\n                position: absolute;\n                right: var(--space-base);\n                top: var(--space-base);\n            }\n        }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"order-card": `orderFilter_order-card__o49+Y`,
	"order-card-value": `orderFilter_order-card-value__1vvAX`,
	"large": `orderFilter_large__-bYLr`,
	"order-card-name": `orderFilter_order-card-name__ozGlO`,
	"order-card-active": `orderFilter_order-card-active__KTw+C`,
	"order-card-container": `orderFilter_order-card-container__puSvz`,
	"active-close-icon": `orderFilter_active-close-icon__fSgyB`
};
export default ___CSS_LOADER_EXPORT___;
