// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.stats_stats__mNX-T {
  height: 100%;
}
.stats_stats-wrapper__SWVpx {
  margin: 0 0 0 var(--space-base) !important;
}
.stats_stats-title__NbDjD, .stats_stats-chart-title__xT6lJ {
  padding: var(--space-base);
}
.stats_stats-chart__i726c {
  margin: var(--space-base) 0 0 0 !important;
  padding: 0 !important;
}`, "",{"version":3,"sources":["webpack://./src/apps/dashboard/components/stats.module.scss"],"names":[],"mappings":"AAAA;EACE,YAAA;AACF;AAAE;EACE,0CAAA;AAEJ;AAAE;EACE,0BAAA;AAEJ;AAAE;EACE,0CAAA;EACA,qBAAA;AAEJ","sourcesContent":[".stats {\n  height: 100%;\n  &-wrapper {\n    margin: 0 0 0 var(--space-base) !important;\n  }\n  &-title, &-chart-title {\n    padding: var(--space-base);\n  }\n  &-chart {\n    margin: var(--space-base) 0 0 0 !important;\n    padding: 0 !important;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"stats": `stats_stats__mNX-T`,
	"stats-wrapper": `stats_stats-wrapper__SWVpx`,
	"stats-title": `stats_stats-title__NbDjD`,
	"stats-chart-title": `stats_stats-chart-title__xT6lJ`,
	"stats-chart": `stats_stats-chart__i726c`
};
export default ___CSS_LOADER_EXPORT___;
