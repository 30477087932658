import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { InputText } from 'primereact/inputtext';
import { Password } from 'primereact/password';
import { Button } from 'primereact/button';
import { userService } from '../../services';
import style from './setPassword.module.scss';
import logo from '../../assets/logo.png';

export default function SetPassword() {
  const [ogPassword, setOgPassword] = useState('');
  const [repeatPassword, setRepeatPassword] = useState('');
  const [status, setStatus] = useState('ready');
  const [passwordValid] = useState({
    passwordsExist: false,
    passwordsMatch: false,
    correctLength: false,
    containsNumber: false,
    lowercaseLetter: false,
    uppercaseLetter: false,
  });
  const [emailId, setEmailId] = useState('');
  const [code, setCode] = useState('');
  const navigate = useNavigate();
  const rules = [
    { condition: 'passwordsMatch', rule: 'Passwords must match' },
    { condition: 'correctLength', rule: 'Password must be between 8 and 32 characters' },
    { condition: 'containsNumber', rule: 'Password must contain a number' },
    { condition: 'lowercaseLetter', rule: 'Password must contain a lowercase letter' },
    { condition: 'uppercaseLetter', rule: 'Password must contain an uppercase letter' },
  ];

  function handleKeyPress(key) {
    if (key === 'Enter') resetPassword();
  }

  async function resetPassword() {
    try {
      setStatus('Loading');
      await userService.resetPassword({ username: emailId, password: ogPassword, code });
      setStatus('PasswordSetMessage');
    }
    catch (e) {
      setStatus('PasswordFailedMessage');
    }
  }

  function setPasswordValid(originalPassword, repeatedPassword) {
    passwordValid.passwordsExist = originalPassword && repeatedPassword;
    passwordValid.passwordsMatch = originalPassword === repeatedPassword;
    passwordValid.correctLength = originalPassword.length >= 8 && originalPassword.length <= 32;
    passwordValid.containsNumber = new RegExp('[0-9]').test(originalPassword);
    passwordValid.lowercaseLetter = new RegExp('[a-z]').test(originalPassword);
    passwordValid.uppercaseLetter = new RegExp('[A-Z]').test(originalPassword);
  }

  function isPasswordValid() {
    return Object.keys(passwordValid).reduce((rulesPassed, passwordRule) => (passwordValid[passwordRule] && rulesPassed), true);
  }

  const PasswordMessage = () => {
    const text = status === 'PasswordSetMessage'
      ? <div className='password-set-successful' data-cy='password-set-success-message'>
        <span>Your password has been set successfully.<br />
          Please make a secure note of your password and remember it can be reset at anytime within the portal.</span>
        <div className='link-div'>
          <Button className={`bg-none color ${style['password-message-button']}`} label='Return to login'
            onClick={() => navigate('/')}></Button>
        </div>
      </div>
      : <div className='password-set-failed' data-cy='password-set-fail-message'>
        <h1>Authentication Failed</h1>
        <span>The authentication details you have entered are incorrect.<br />
          If this issue persists please contact your system administrator.</span>
        <Button className={`bg-none color ${style['password-message-button']}`} label='Try again' onClick={
          () => {
            setStatus('ready');
            setOgPassword('');
            setRepeatPassword('');
            setPasswordValid('', '');
          }} />
      </div>;
    return <div className='message'>
      {text}
    </div>;
  };

  function generateRule({ condition, rule }) {
    return <span className={style['passwords-rule']}>
      <i className={`pi ${style['passwords-rule-icon']} ${passwordValid[condition] ? 'pi-check' : 'pi-times'}`}></i>
      <span className={style['passwords-rule-text']}>{rule}</span>
    </span>;
  }

  return (
    <div className='heading'>
      <div className='logoContainer'>
        <img src={logo} alt='midlands steel logo' />
      </div>
      <div className='w-25rem'>
        {status.includes('Password')
          ? <PasswordMessage />
          : <div className='unauthed-section'>
            <div className='mt-8 p-1'>
              <InputText className={`w-full bg-none color ${status}`} type='text' placeholder='Email' value={emailId}
                onChange={(e) => setEmailId(e.target.value)} onKeyDown={(e) => handleKeyPress(e.key)} />
            </div>
            <div className='p-1'>
              <Password className={`w-full color ${status}`} value={ogPassword} placeholder='Enter new password'
                onChange={(e) => { setOgPassword(e.target.value); setPasswordValid(e.target.value, repeatPassword); }}
                onKeyDown={(e) => {if (isPasswordValid()) handleKeyPress(e.key);}}
                toggleMask feedback={false} />
            </div>
            <div className='p-1'>
              <Password className={`w-full color ${status}`} value={repeatPassword} placeholder='Repeat new password'
                onChange={(e) => { setRepeatPassword(e.target.value); setPasswordValid(ogPassword, e.target.value); }}
                onKeyDown={(e) => {if (isPasswordValid()) handleKeyPress(e.key);}}
                toggleMask feedback={false} />
            </div>
            <div className='p-1'>
              <InputText className={`w-full bg-none color ${status}`} type='text' placeholder='Verification code' value={code}
                onChange={(e) => setCode(e.target.value)} onKeyDown={(e) => handleKeyPress(e.key)} />
            </div>
            <div className='passwords'> {rules.map(rule => generateRule(rule))} </div>
            <div className='p-1 mt-3'>
              <Button className='w-full bg-none color' disabled={!emailId || !isPasswordValid() || status === 'Loading'}
                label='Next' onClick={resetPassword} />
            </div>
            <div className='link-div'>
              <a href='/' className='link' onClick={() => navigate('/')}>Return to login</a>
            </div>
          </div>}
      </div>
    </div>
  );
}
