import React, { useEffect, useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { utils } from '../../../utils';
import styles from './stats.module.scss';
import { AgCharts } from 'ag-charts-react';

function Stats({ processes }) {
  const [graph, setGraph] = useState();
  const [donut, setOptions] = useState();
  const formatData = useCallback(() => {
    // let total = 0;
    const todayDate = new Date();
    const lineData = [];
    for (let i = 0; i < 7; i++) {
      const date = new Date();
      date.setDate(date.getDate() - i);
      lineData.push({
        date: utils.dateFormatting.shortDate(date),
        dateString: date.toDateString(),
        percentage: 0,
      });
    }
    setOptions({
      data: Object.keys(processes).map(key => {
        // total += processes[key].length;
        return {
          type: key,
          length: processes[key].filter(process => new Date(process.date).toDateString() === todayDate.toDateString()).length,
        };
      }),
      legend: {
        position: 'right',
      },
      series: [
        {
          type: 'donut',
          calloutLabelKey: 'type',
          angleKey: 'length',
          innerRadiusRatio: 0.8,
          innerLabels: [
            {
              text: `11.1%`,
              fontSize: 26,
            },
          ],
          calloutLabel: { enabled: false },
          fills: [
            '#6FB2CE',
            '#EA0A17',
            '#C083C5',
            '#9F9594',
          ],
        },
      ],
    });
    setGraph({
      data: lineData.map(line => {
        let total = 0;
        let success = 0;
        Object.keys(processes).forEach(key => {
          const length = processes[key].filter(process => new Date(process.date).toDateString() === line.dateString).length;
          total += length;
          if (key === 'Success') success = length;
        });
        line.percentage = Math.round((success / total) * 10000) / 100;
        return line;
      }).reverse(),
      series: [
        { type: 'line', xKey: 'date', yKey: 'percentage', yName: 'Percentage' },
      ],
    });
  }, [processes]);
  useEffect(() => { formatData(); }, [formatData]);

  return (
    <div className={`${styles['stats']} layer1`}>
      <span className={styles['stats-title']}>Monitoring</span>
      <div className={`${styles['stats-wrapper']} grid`}>
        <div className={`${styles['stats-chart']} col-12 md:col-7 layer2 grid-border-layer-2`}>
          <span className={styles['stats-chart-title']}>Todays Transfers</span>
          <AgCharts options={donut} />
        </div>
        <div className={`${styles['stats-chart']} col-12 md:col-5 layer2 grid-border-layer-2`}>
          <span className={styles['stats-chart-title']}>Last 7 days</span>
          <AgCharts options={graph} />
        </div>
      </div>
    </div >);
}

Stats.propTypes = {
  processes: PropTypes.object,
};

export default Stats;
