import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Button } from 'primereact/button';
import Configurations from '../../components/configurationsTable';
import styles from './configurations.module.scss';

function ConfigTable() {
  const navigate = useNavigate();
  return (<div className={`${styles['wrapper']} flex flex-row`}>
    <div className={`${styles['config-table']}`} data-cy={`config-table`}>
      <div className={styles['config-table-actions']}>
        <Button className={styles['config-table-actions-button']} label='Create configuration'
          onClick={() => navigate('/configuration')}></Button>
      </div>
      <div className={`${styles['config-table-container']} layer1`}>
        <Configurations></Configurations>
      </div>
    </div>
  </div>);
}

export default ConfigTable;
