// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.heading {
  background: var(--login-background);
  color: var(--login-text);
  width: 100%;
  min-height: 100vh;
  height: -webkit-fill-available;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.logoContainer {
  margin-right: 25%;
}

.unauthed-section .p-password-input::placeholder, .unauthed-section .p-inputtext::placeholder {
  color: var(--login-text) !important;
}
.unauthed-section .p-inputtext.p-component {
  background: white !important;
}
.unauthed-section .p-password-input {
  width: 100%;
  background: none;
  color: var(--login-text) !important;
  border-color: var(--login-text) !important;
  background: white !important;
}
.unauthed-section .color {
  color: var(--login-text) !important;
  border-color: var(--login-text) !important;
}
.unauthed-section .bg-none {
  background: none;
}
.unauthed-section .p-invalid, .unauthed-section .p-invalid.p-component > .p-inputtext {
  border-color: var(--red) !important;
}

.link-div {
  padding-top: var(--space-base);
}

.link {
  color: var(--login-text) !important;
  display: block;
  text-align: center;
}
.link:hover {
  -webkit-text-decoration: underline solid var(--login-text);
          text-decoration: underline solid var(--login-text);
  cursor: pointer;
}

.link:hover {
  -webkit-text-decoration: underline solid var(--login-text);
          text-decoration: underline solid var(--login-text);
  cursor: pointer;
}`, "",{"version":3,"sources":["webpack://./src/styles/login.scss"],"names":[],"mappings":"AAAA;EACI,mCAAA;EACA,wBAAA;EACA,WAAA;EACA,iBAAA;EACA,8BAAA;EACA,aAAA;EACA,sBAAA;EACA,uBAAA;EACA,mBAAA;AACJ;;AAEA;EACI,iBAAA;AACJ;;AAGI;EACI,mCAAA;AAAR;AAGI;EACI,4BAAA;AADR;AAII;EACI,WAAA;EACA,gBAAA;EACA,mCAAA;EACA,0CAAA;EACA,4BAAA;AAFR;AAII;EACI,mCAAA;EACA,0CAAA;AAFR;AAII;EACI,gBAAA;AAFR;AAII;EACI,mCAAA;AAFR;;AAMA;EACI,8BAAA;AAHJ;;AAKA;EACI,mCAAA;EACA,cAAA;EACA,kBAAA;AAFJ;AAGI;EACE,0DAAA;UAAA,kDAAA;EACA,eAAA;AADN;;AAIA;EACI,0DAAA;UAAA,kDAAA;EACA,eAAA;AADJ","sourcesContent":[".heading {\n    background: var(--login-background);\n    color: var(--login-text);\n    width: 100%;\n    min-height: 100vh;\n    height: -webkit-fill-available;\n    display: flex;\n    flex-direction: column;\n    justify-content: center;\n    align-items: center;\n}\n\n.logoContainer {\n    margin-right: 25%;\n}\n\n.unauthed-section {\n    .p-password-input::placeholder, .p-inputtext::placeholder {\n        color: var(--login-text) !important;\n    }\n\n    .p-inputtext.p-component {\n        background: white !important;\n    }\n\n    .p-password-input {\n        width: 100%;\n        background: none;\n        color: var(--login-text) !important;\n        border-color: var(--login-text) !important;\n        background: white !important;\n    }\n    .color {\n        color: var(--login-text) !important;\n        border-color: var(--login-text) !important;\n    }\n    .bg-none {\n        background: none;\n    }\n    .p-invalid, .p-invalid.p-component > .p-inputtext {\n        border-color: var(--red)  !important;\n    }\n}\n\n.link-div {\n    padding-top: var(--space-base);\n}\n.link {\n    color: var(--login-text) !important;\n    display: block;\n    text-align: center;\n    &:hover {\n      text-decoration: underline solid var(--login-text);\n      cursor: pointer;\n    }\n}\n.link:hover {\n    text-decoration: underline solid var(--login-text);\n    cursor: pointer;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
