import { api } from '../utils';

function getConfigurations(id) {
  return api.send({
    endpoint: `/config${id ? `/${id}` : ''}`,
  });
}

function upsertConfigurations(id, json) {
  let endpoint = '/config';
  let method = 'POST';
  if (id) {
    endpoint = `/configurations/${id}`;
    method = 'PUT';
  }

  return api.send({
    endpoint,
    json,
    method,
  });
}

export const configService = {
  upsertConfigurations,
  getConfigurations,
};
