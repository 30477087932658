function getMenu({ roles }) {
  const adminArray = roles.includes('OVERALL_ADMIN')
    ? [{ name: 'Administrator', children: [
      { name: 'Users', route: '/users' },
      { name: 'Create new user', route: '/user', exclude: true },
    ] }]
    : [];

  let menu = [
    { name: 'Home', route: '/dashboard' },
    { name: 'Monitoring', route: '/processes' },
    { name: 'Configurations', route: '/configurations' },
    { name: 'Create configuration', route: '/configuration', exclude: true },
  ];
  return menu.concat(adminArray);
}

export const menuService = {
  getMenu,
};
