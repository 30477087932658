import React, { useEffect, useCallback, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button } from 'primereact/button';
import { ProgressSpinner } from 'primereact/progressspinner';
import Announcements from './components/announcements';
import Stats from './components/stats';
import Configurations from '../../components/configurationsTable';
import { processService } from '../../services';
import styles from './dashboard.module.scss';
import ServiceErrorMessage from '../../components/serviceErrorMessage';

function Dashboard() {
  const navigate = useNavigate();
  const [processes, setProcesses] = useState({
    Success: [],
    Pending: [],
    Failed: [],
    Unprocessed: [],
  });
  const [state, setState] = useState('loading');
  const fetchData = useCallback(async () => {
    let result;
    try {
      result = await processService.getProcesses();
      setProcesses(processService.sortByType(result.processes));
      setState('loaded');
    } catch (e) {
      setState('error');
    }
  }, []);

  const Dashboard = () => (<div>
    <div className={`${styles['summary-container']} grid`}>
      <div className={`col-12 md:col-6 lg:col-8 grid-border-layer-1 ${styles['summary-container-row-top']}`}>
        <Stats processes={processes}></Stats>
      </div>
      <div className={`col-12 md:col-6 lg:col-4 grid-border-layer-1 layer1 ${styles['summary-container-row-top']}`}>
        <div className={styles['config-actions']}>
          <span className={styles['config-actions-title']}>Configurations</span>
          <Button className={styles['config-actions-button']} label='View all'
            onClick={() => navigate('/configurations')}></Button>
        </div>
        <div className={`${styles['config-table']} layer2`}>
          <Configurations smallTable={true}></Configurations>
        </div>
      </div>
      <div className={`grid grid-border-layer-1 layer1 ${styles['summary-container-row-bottom']}`}>
        <div className={`col-12 md:col-6 lg:col-3 grid-border-layer-2 overflow-y-auto ${styles['announcement-container-first']}`}>
          <Announcements announcements={processes.Success} type="success" />
        </div>
        <div className={`col-12 md:col-6 lg:col-3 grid-border-layer-2 overflow-y-auto ${styles['announcement-container']}`}>
          <Announcements announcements={processes.Pending} type="pending" />
        </div>
        <div className={`col-12 md:col-6 lg:col-3 grid-border-layer-2 overflow-y-auto ${styles['announcement-container']}`}>
          <Announcements announcements={processes.Failed} type="failed" />
        </div>
        <div className={`col-12 md:col-6 lg:col-3 grid-border-layer-2 overflow-y-auto ${styles['announcement-container']}`}>
          <Announcements announcements={processes.Unprocessed} type="unprocessed" />
        </div>
      </div>
    </div>
  </div>);

  useEffect(() => { fetchData(); }, [fetchData]);
  return (
    state === 'loading'
      ? <div className='loading-mask'><ProgressSpinner className='spinner'/></div>
      : state === 'error'
        ? <ServiceErrorMessage></ServiceErrorMessage>
        : <Dashboard></Dashboard>);
}

export default Dashboard;
