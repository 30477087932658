import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import { utils } from '../../../utils';
import styles from './announcements.module.scss';
import { ThemeContext } from '../../../contexts/theme-context';

function Announcements({ announcements, type }) {
  const [mainAnnouncement, setMainAnnouncement] = useState({});
  const [visible, setVisible] = useState(false);
  const { theme } = useContext(ThemeContext);

  const AnnouncementDialog = () => (<div>
    <Dialog className={theme} header={mainAnnouncement.title} data-cy='announcement-dialog'
      footer={<Button data-cy='close-announcement-dialog' onClick={() => setVisible(false)}>Close</Button>} visible={visible}
      onHide={() => setVisible(false)}>
      <div>
        {mainAnnouncement.description}
      </div>
      <a href={'/processes'}>View all process messages here</a>
    </Dialog>
  </div>);

  return (
    <div className={`layer2 ${styles.announcements}`}>
      <div className={styles['card-title']} data-cy='activity-card-title'>{`${utils.capitalize(type)} files`}</div>
      <div className={styles['announcements-wrapper']}>
        {(announcements || []).map((announcement, index) => (
          <div key={`type-${index}`} className={`${styles['announcements-wrapper-item']} layer2 hover-enabled`}
            data-cy={`announcement-item`}
            onClick={() => { setMainAnnouncement(announcement); setVisible(true); }}>
            <div className={styles['announcements-wrapper-item-container']}>
              <div className={styles['announcements-wrapper-item-container-low']}>
                <div className={`${styles['bullet']} ${styles[`bullet-${type}`]}`}></div>
                <div className={styles['main-row']}>
                  <div className={styles['main-row-date']}>{utils.dateFormatting.shortDateTime(announcement.date)}</div>
                  <div className={styles['main-row-title']}>{utils.capitalize(announcement.title)}</div>
                  <div className={styles['main-row-description']}>
                    {announcement.description.length > 70 ? `${announcement.description.substring(0, 70)}...` : announcement.description}
                  </div>
                </div>
              </div>
            </div>
          </div>
        ))}
        {mainAnnouncement ? <AnnouncementDialog /> : null}
      </div>
    </div>
  );
}

Announcements.propTypes = {
  announcements: PropTypes.array,
  type: PropTypes.string,
};

export default Announcements;
