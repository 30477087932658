// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.header_header__fCG2X {
  padding: var(--space-base);
  background: var(--header);
}
.header_header-logo__8a6Cm {
  height: var(--space-xxxl);
}`, "",{"version":3,"sources":["webpack://./src/components/header.module.scss"],"names":[],"mappings":"AAAA;EACE,0BAAA;EACA,yBAAA;AACF;AAAE;EACE,yBAAA;AAEJ","sourcesContent":[".header {\n  padding: var(--space-base);\n  background: var(--header);\n  &-logo {\n    height: var(--space-xxxl);\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"header": `header_header__fCG2X`,
	"header-logo": `header_header-logo__8a6Cm`
};
export default ___CSS_LOADER_EXPORT___;
