// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.dashboard_summary-container__du8dh {
  margin: 0 0 0 var(--space-base) !important;
  min-height: calc(100vh - 98px);
  padding-bottom: var(--space-base);
}
.dashboard_summary-container-row-top__wgZ23 {
  margin: var(--space-base) 0 0 0 !important;
  padding: 0 !important;
}
@media screen and (min-width: 992px) {
  .dashboard_summary-container-row-top__wgZ23 {
    min-height: 330px;
  }
}
.dashboard_summary-container-row-bottom__K5S4c {
  margin: var(--space-base) 0 0 0 !important;
  padding: var(--space-base) 0 !important;
  flex: 1 1 auto;
}

.dashboard_config-actions__FXZCm {
  display: block;
  height: 40px;
  padding: var(--space-base);
}
.dashboard_config-actions-title__0pUk7 {
  font-size: var(--header2);
}
.dashboard_config-actions-button__bRDIS {
  float: right;
}
.dashboard_config-table__fxTfV {
  margin: var(--space-base);
}

.dashboard_announcement-container__VgpO0 {
  max-height: 425px;
  padding: var(--space-base) 0 !important;
}
@media screen and (max-width: 768px) {
  .dashboard_announcement-container__VgpO0 {
    padding: var(--space-base) 0 var(--space-base) var(--space-base) !important;
  }
}
.dashboard_announcement-container-first__DwMHq {
  max-height: 425px;
  padding: var(--space-base) 0 var(--space-base) var(--space-base) !important;
}`, "",{"version":3,"sources":["webpack://./src/apps/dashboard/dashboard.module.scss"],"names":[],"mappings":"AAAA;EACI,0CAAA;EACA,8BAAA;EACA,iCAAA;AACJ;AACQ;EAKI,0CAAA;EACA,qBAAA;AAHZ;AAFY;EADJ;IAEQ,iBAAA;EAKd;AACF;AACQ;EACI,0CAAA;EACA,uCAAA;EACA,cAAA;AACZ;;AAKI;EACI,cAAA;EACA,YAAA;EACA,0BAAA;AAFR;AAIQ;EACI,yBAAA;AAFZ;AAKQ;EACI,YAAA;AAHZ;AAOI;EACI,yBAAA;AALR;;AASA;EACI,iBAAA;EACA,uCAAA;AANJ;AAOI;EAHJ;IAIQ,2EAAA;EAJN;AACF;AAKI;EACI,iBAAA;EACA,2EAAA;AAHR","sourcesContent":[".summary-container {\n    margin: 0 0 0 var(--space-base) !important;\n    min-height: calc(100vh - 98px);\n    padding-bottom: var(--space-base);\n    &-row {\n        &-top {\n            @media screen and (min-width: 992px) {\n                min-height: 330px;\n            }\n\n            margin: var(--space-base) 0 0 0 !important;\n            padding: 0 !important;\n        }\n\n        &-bottom {\n            margin: var(--space-base) 0 0 0 !important;\n            padding: var(--space-base) 0 !important;\n            flex: 1 1 auto;\n        }\n    }\n}\n\n.config {\n    &-actions {\n        display: block;\n        height: 40px;\n        padding: var(--space-base);\n\n        &-title {\n            font-size: var(--header2);\n        }\n\n        &-button {\n            float: right;\n        }\n    }\n\n    &-table {\n        margin: var(--space-base);\n    }\n}\n\n.announcement-container {\n    max-height: 425px;\n    padding: var(--space-base) 0 !important;\n    @media screen and (max-width: 768px) {\n        padding: var(--space-base) 0 var(--space-base) var(--space-base)  !important;\n    }\n    &-first {\n        max-height: 425px;\n        padding: var(--space-base) 0 var(--space-base) var(--space-base)  !important;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"summary-container": `dashboard_summary-container__du8dh`,
	"summary-container-row-top": `dashboard_summary-container-row-top__wgZ23`,
	"summary-container-row-bottom": `dashboard_summary-container-row-bottom__K5S4c`,
	"config-actions": `dashboard_config-actions__FXZCm`,
	"config-actions-title": `dashboard_config-actions-title__0pUk7`,
	"config-actions-button": `dashboard_config-actions-button__bRDIS`,
	"config-table": `dashboard_config-table__fxTfV`,
	"announcement-container": `dashboard_announcement-container__VgpO0`,
	"announcement-container-first": `dashboard_announcement-container-first__DwMHq`
};
export default ___CSS_LOADER_EXPORT___;
