import { userService } from '../services';
import { utils } from '../utils';

class Api {
  constructor() {
    this.version = 'v1';
    this.controller = new window.AbortController();
    this.signal = this.controller.signal;
  }

  kill() {
    this.controller.abort();
  }

  send(options) {
    const guid = utils.generateGuid();
    const settings = Object.assign(
      {
        headers: {
          'content-type': 'application/json',
          'x-correlation-id': guid,
        },
        mode: 'cors',
        credentials: 'include',
        body: options.json != null ? JSON.stringify(options.json) : undefined,
        signal: !options.endpoint.includes('/logout') ? this.signal : undefined,
      },
      options,
    );
    return window.fetch(`/api/${this.version}${options.endpoint}`, settings)
      .then(response => {
        if (this.signal.aborted) {
          return false;
        }
        userService.setLogoutTime(parseFloat(response.headers.get('x-expires-at')));
        if (response.status === 403) {
          return userService.logout();
        }
        if (response.status === 204) return true;
        if (response.status > 499) return { error: { message: 'Unexpected error' } };
        return response.json();
      })
      .then(json => {
        if (json.error) {
          let error = new Error(json.error.message);
          if (json.error.message['error-code']) {
            error = {
              message: json.error.message['error-code'], name :  json.error.message['service'],
            };
          }

          error.guid = guid;
          throw error;
        } else {
          return json;
        }
      });
  }
}

export const api = new Api();
