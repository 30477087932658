import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { InputText } from 'primereact/inputtext';
import { Password } from 'primereact/password';
import { Button } from 'primereact/button';
import QRCode from 'react-qr-code';
import { userService } from '../../services';
import styles from './login.module.scss';
import logo from '../../assets/logo.png';

export default function Login() {
  const [emailId, setEmailId] = useState('');
  const [password, setPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [totpCode, setOTP] = useState('');
  const [status, setStatus] = useState('ready');
  const [error, setError] = useState('');
  const [qrUrl, setQRUrl] = useState();
  const navigate = useNavigate();

  function handleKeyPress(key) {
    if (key === 'Enter') loginUser();
  }

  async function loginUser() {
    try {
      await userService.login(emailId, password, totpCode, status === 'temp-password' ? newPassword : undefined);
      setError('');
      setStatus('Loading');
      navigate('/dashboard');
    }
    catch (e) {
      if (e.message.includes('verification required')) {
        setStatus('mfa-invalid');
        setQRUrl(e.message.split(' - ')[2]);
      } else if (e.message.includes('New password required')) {
        setStatus('temp-password');
      } else {
        setError('p-invalid');
      }
    }
  }

  async function verifyUser() {
    try {
      setError('');
      setStatus('Loading');
      setOTP(null);
      await userService.verifyUser(emailId, totpCode);
      setStatus('ready');
    }
    catch (e) {
      setError('p-invalid');
    }
  }

  const LoginErrorMessage = () => (
    <div className={styles.errorMessage} data-cy="login-error">
      You have entered an incorrect email, password or code.<br/>
      Please check your credentials and try again. If the problem persists,
      please contact your system administrator
    </div>
  );

  const QrCode = () => (<div>
    <div className={styles['qr-background']}>
      <QRCode
        size={128}
        value={qrUrl}
        viewBox={`0 0 128 128`}
      />
    </div>
    <p>
    Please scan the qr code into your authenticator. Once successful please supply your one time password to verify and login.
    </p>
    <div className='p-1 mt-3'>
      <Button className='w-full bg-none color' label='Verify'
        onClick={verifyUser} />
    </div>
  </div>);

  return (
    <div className='heading'>
      <div className='logoContainer'>
        <img src={logo} alt='midlands steel logo' />
      </div>
      <div className='w-25rem unauthed-section'>
        <div className='mt-4 p-1'>
          <InputText className={`w-full bg-none color ${error}`} type='text' placeholder='Email' value={emailId}
            onChange={(e) => setEmailId(e.target.value)} onKeyDown={(e) => handleKeyPress(e.key)} />
        </div>
        <div className='p-1 color'>
          <Password className={`w-full color ${error}`} value={password} placeholder='Password'
            onChange={(e) => setPassword(e.target.value)} toggleMask feedback={false} onKeyDown={(e) => handleKeyPress(e.key)} />
        </div>
        { status !== 'temp-password'
          ? <div className='p-1'>
            <InputText className={`w-full bg-none color ${error}`} type='text' placeholder='Timed OTP' value={totpCode}
              onChange={(e) => setOTP(e.target.value)} onKeyDown={(e) => handleKeyPress(e.key)} />
          </div>
          : <div className='p-1 color'>
            <Password className={`w-full color ${error}`} value={newPassword} placeholder='New Password'
              onChange={(e) => setNewPassword(e.target.value)} toggleMask feedback={false} onKeyDown={(e) => handleKeyPress(e.key)} />
            <p>
              Please enter a new password to overwrite your current temporary password.
            </p>
          </div>
        }
        { error === 'p-invalid' ? <LoginErrorMessage /> : null }
        { status === 'mfa-invalid'
          ? <QrCode />
          : <div>
            <div className='mt-3'>
              <Button className='w-full bg-white color' disabled={!(emailId && password) || status === 'Loading'} label='Login'
                onClick={loginUser} />
            </div>
            <div className='link-div'>
              <a href='/forgotten-password' className='link' onClick={() => navigate('/forgotten-password')}>Forgotten password</a>
            </div>
            <div className='link-div'>
              <a href='/set-password' className='link' onClick={() => navigate('/set-password')}>Reset password with token</a>
            </div>
          </div>
        }
      </div>
      <div className='w-30rem text-justify'>
        <p>
                    Log in is only allowed for authorized users. If you are not an authorized user, please exit immediately.
                    In accordance with requirements of data protection laws,
                    we hereby inform you that personally identifiable information will be handled in log files for legal,
                    security and cost reasons.
          <br></br><br></br>
                    © Nova, 2023-2024. All rights reserved.
        </p>
      </div>
    </div>
  );
}
